import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a21c14c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "drawer fixed bg-white shadow-md inset-0 flex flex-col md:inset-y-0 md:right-0 md:left-auto md:w-2/3 lg:w-1/3",
  role: "dialog"
}
const _hoisted_2 = { class: "flex flex-row items-center px-5 py-2" }
const _hoisted_3 = {
  key: 0,
  class: "pr-2"
}
const _hoisted_4 = { class: "flex-1 pr-2 text-base font-bold text-gray-800" }
const _hoisted_5 = { class: "flex-1 overflow-x-hidden overflow-y-auto" }
const _hoisted_6 = { class: "mx-5 my-3" }
const _hoisted_7 = { class: "px-5 py-2 flex md:justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snap_link = _resolveComponent("snap-link")!
  const _component_snap_close_button = _resolveComponent("snap-close-button")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "drawer" }, {
      default: _withCtx(() => [
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("header", _hoisted_2, [
                (_ctx.isBack)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_snap_link, {
                        size: "base",
                        variant: "default",
                        icon: "arrow-left-solid",
                        "icon-position": "left",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back')))
                      })
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.title), 1),
                _createVNode(_component_snap_close_button, {
                  color: "gray",
                  onClick: _ctx.close
                }, null, 8, ["onClick"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _renderSlot(_ctx.$slots, "default", {
                    close: _ctx.close,
                    state: _ctx.state
                  })
                ])
              ]),
              _createElementVNode("footer", _hoisted_7, [
                _renderSlot(_ctx.$slots, "actions", {
                  close: _ctx.close,
                  state: _ctx.state
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}