import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "target"]
const _hoisted_2 = { class: "hover:underline inline-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snap_icon = _resolveComponent("snap-icon")!

  return (_openBlock(), _createElementBlock("a", {
    href: _ctx.href || 'javascript:void(0)',
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
    class: "text-blue-700 font-semibold",
    target: _ctx.target || '_self'
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_snap_icon, {
          key: 0,
          icon: _ctx.icon,
          size: "xs",
          class: "align-text-top m-0.5"
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 8, _hoisted_1))
}