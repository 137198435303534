import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-start gap-2",
  "data-testid": "form-error-inline"
}
const _hoisted_2 = { class: "flex-1 text-red-600 text-sm font-semibold" }
const _hoisted_3 = {
  key: 0,
  role: "list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snap_icon = _resolveComponent("snap-icon")!

  return (_ctx.error)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_snap_icon, {
          icon: "exclamation-circle-solid",
          color: "#DC2626",
          size: "sm"
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", null, _toDisplayString(_ctx.error.message), 1),
          (Array.isArray(_ctx.error.description) && _ctx.error.description.length > 0)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.error.description, (text) => {
                  return (_openBlock(), _createElementBlock("li", { key: text }, _toDisplayString(text), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}