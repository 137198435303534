import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e8854de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "absolute w-full md:w-auto content-position min-h-screen md:min-h-0 flex flex-col md:block md:mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snap_logo = _resolveComponent("snap-logo")!

  return (_openBlock(), _createElementBlock("div", {
    class: "min-h-screen flex justify-center items-center participants-background",
    style: _normalizeStyle({
      'background-color': _ctx.backgroundColor,
    })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_snap_logo, {
        class: "logo-size",
        product: "snap-logo-raise",
        white: _ctx.white,
        variant: "1line"
      }, null, 8, ["white"]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 4))
}