import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snap_alert_title = _resolveComponent("snap-alert-title")!
  const _component_snap_alert_description = _resolveComponent("snap-alert-description")!
  const _component_snap_alert = _resolveComponent("snap-alert")!

  return _withDirectives((_openBlock(), _createBlock(_component_snap_alert, {
    type: _ctx.type,
    class: "mb-2",
    border: "true",
    close: "true",
    onSnapAlertClosed: _ctx.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_snap_alert_title, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }),
      _createVNode(_component_snap_alert_description, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "description")
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["type", "onSnapAlertClosed"])), [
    [_vShow, _ctx.isOpened]
  ])
}