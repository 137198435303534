import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snap_tabs = _resolveComponent("snap-tabs")!

  return (_openBlock(), _createBlock(_component_snap_tabs, {
    type: _ctx.type || 'primary',
    options: _ctx.options,
    onSnapTabsItemSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onItemSelected($event.detail)))
  }, null, 8, ["type", "options"]))
}