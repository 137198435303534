import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snap_link = _resolveComponent("snap-link")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.href)
      ? (_openBlock(), _createBlock(_component_snap_link, {
          key: 0,
          href: _ctx.href,
          size: _ctx.size,
          class: "inline-block"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["href", "size"]))
      : _createCommentVNode("", true),
    (_ctx.to)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: _ctx.to,
          class: "inline-block"
        }, {
          default: _withCtx(({ href, navigate }) => [
            _createVNode(_component_snap_link, {
              href: href,
              size: _ctx.size,
              onClick: navigate
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _: 2
            }, 1032, ["href", "size", "onClick"])
          ]),
          _: 3
        }, 8, ["to"]))
      : _createCommentVNode("", true)
  ], 64))
}