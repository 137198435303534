import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FamilyTree = _resolveComponent("FamilyTree")!
  const _component_PageContent = _resolveComponent("PageContent")!
  const _component_UserChildCreateForm = _resolveComponent("UserChildCreateForm")!
  const _component_snap_button = _resolveComponent("snap-button")!
  const _component_Drawer = _resolveComponent("Drawer")!
  const _component_UserChildUpdateForm = _resolveComponent("UserChildUpdateForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PageContent, { title: "Guardian Portal" }, {
      actions: _withCtx(() => []),
      default: _withCtx(() => [
        _createVNode(_component_FamilyTree, {
          ref: "familyTree",
          "onChild:create": _ctx.onCreateChild,
          "onChild:update": _ctx.onUpdateChild
        }, null, 8, ["onChild:create", "onChild:update"])
      ]),
      _: 1
    }),
    _createVNode(_component_Drawer, {
      ref: "childCreatePanel",
      title: "Add Child Account"
    }, {
      default: _withCtx(({ close, state }) => [
        _createVNode(_component_UserChildCreateForm, {
          ref: "childCreateForm",
          "onForm:success": ($event: any) => {
          close();
          _ctx.refetchFamilyTree();
        },
          "onState:normal": ($event: any) => (state.set('NORMAL')),
          "onState:submitting": ($event: any) => (state.set('SUBMITTING'))
        }, null, 8, ["onForm:success", "onState:normal", "onState:submitting"])
      ]),
      actions: _withCtx(({ close, state }) => [
        _createVNode(_component_snap_button, {
          "button-type": "button",
          size: "sm",
          variant: "tertiary",
          onClick: close,
          disabled: state.is('SUBMITTING'),
          "full-width": ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel ")
          ]),
          _: 2
        }, 1032, ["onClick", "disabled"]),
        _createVNode(_component_snap_button, {
          "button-type": "button",
          size: "sm",
          variant: "primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$refs.childCreateForm as UserChildCreateForm)?.create())),
          class: "inline-block",
          disabled: state.is('SUBMITTING'),
          "full-width": ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("Save ")
          ]),
          _: 2
        }, 1032, ["disabled"])
      ]),
      _: 1
    }, 512),
    _createVNode(_component_Drawer, {
      ref: "childUpdatePanel",
      title: "Update Child Account"
    }, {
      default: _withCtx(({ close, state }) => [
        _createVNode(_component_UserChildUpdateForm, {
          child: _ctx.child,
          ref: "childUpdateForm",
          "onForm:success": ($event: any) => {
          close();
          _ctx.refetchFamilyTree();
        },
          "onState:normal": ($event: any) => (state.set('NORMAL')),
          "onState:submitting": ($event: any) => (state.set('SUBMITTING'))
        }, null, 8, ["child", "onForm:success", "onState:normal", "onState:submitting"])
      ]),
      actions: _withCtx(({ close, state }) => [
        _createVNode(_component_snap_button, {
          "button-type": "button",
          size: "sm",
          variant: "tertiary",
          onClick: close,
          disabled: state.is('SUBMITTING'),
          "full-width": ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel ")
          ]),
          _: 2
        }, 1032, ["onClick", "disabled"]),
        _createVNode(_component_snap_button, {
          "button-type": "button",
          size: "sm",
          variant: "primary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$refs.childUpdateForm as UserChildUpdateForm)?.update())),
          class: "inline-block",
          disabled: state.is('SUBMITTING'),
          "full-width": ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("Save ")
          ]),
          _: 2
        }, 1032, ["disabled"])
      ]),
      _: 1
    }, 512)
  ], 64))
}