import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block cursor-pointer relative" }
const _hoisted_2 = {
  key: 0,
  class: "block absolute right-0 -bottom-px bg-blue-500 rounded-full border-2 border-white w-6 h-6 leading-6 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snap_avatar = _resolveComponent("snap-avatar")!
  const _component_snap_icon = _resolveComponent("snap-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_snap_avatar, {
      "image-src": _ctx.user?.profilePicture || '',
      size: _ctx.mq.mobile ? 'md' : 'lg',
      class: "rounded-full border border-blue-600"
    }, null, 8, ["image-src", "size"]),
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_snap_icon, {
                key: 0,
                icon: _ctx.icon,
                color: "#fff",
                size: "xs",
                class: "pt-0.5"
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}