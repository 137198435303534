import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f801099"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "mx-2 text-sm hidden md:block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snap_app_switcher = _resolveComponent("snap-app-switcher")!
  const _component_snap_avatar = _resolveComponent("snap-avatar")!
  const _component_snap_icon = _resolveComponent("snap-icon")!
  const _component_snap_dropdown = _resolveComponent("snap-dropdown")!
  const _component_Self = _resolveComponent("Self")!
  const _component_snap_support_modal = _resolveComponent("snap-support-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Self, null, {
      default: _withCtx(({ profilePicture, fullName, email }) => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.isAppSwitcherVisible)
            ? (_openBlock(), _createBlock(_component_snap_app_switcher, {
                badge: "true",
                ".color": _ctx.color,
                key: _ctx.appSwitcherKey,
                class: "pr-2.5 border-r-2 border-gray-300"
              }, null, 40, [".color"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args)), ["prevent"])),
            class: "flex items-center cursor-pointer",
            style: _normalizeStyle({ color: _ctx.color || '#000' })
          }, [
            _createVNode(_component_snap_avatar, {
              "image-src": profilePicture || '',
              size: _ctx.avatarSize,
              "notification-side": _ctx.unconfirmedAffiliationCount > 0 ? 'top' : '',
              "notification-color": "#D97706"
            }, null, 8, ["image-src", "size", "notification-side"]),
            _createElementVNode("div", _hoisted_2, _toDisplayString(fullName), 1),
            _createVNode(_component_snap_icon, {
              class: "hidden md:block mt-1",
              icon: _ctx.isOpened ? 'chevron-up-solid' : 'chevron-down-solid',
              size: "xs"
            }, null, 8, ["icon"])
          ], 4),
          _createVNode(_component_snap_dropdown, {
            title: "Signed in as",
            description: email,
            ".options": _ctx.options,
            "hide-button": "true",
            "track-current-selection": "false",
            "modal-type": "drawer",
            onSnapDropdownItemSelected: _ctx.onMenuItemSelected,
            onSnapDropdownClosed: _ctx.onDropDownClose,
            ref: "dropdown"
          }, null, 40, ["description", ".options", "onSnapDropdownItemSelected", "onSnapDropdownClosed"])
        ])
      ]),
      _: 1
    }),
    (_ctx.isSupportModalOpened)
      ? (_openBlock(), _createBlock(_component_snap_support_modal, {
          key: 0,
          title: "Learning Center",
          onSnapModalClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isSupportModalOpened = false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}