import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snap_input = _resolveComponent("snap-input")!

  return (_openBlock(), _createBlock(_component_snap_input, _mergeProps({ class: "block bg-transparent" }, _ctx.attrs, {
    value: _ctx.modelValue,
    onInput: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onInput($event)), ["prevent"])),
    onSnapInputFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focus', $event))),
    onSnapInputBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur', $event)))
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["value"]))
}