import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snap_button = _resolveComponent("snap-button")!

  return (_ctx.link)
    ? (_openBlock(), _createBlock(_component_snap_button, {
        key: 0,
        size: "xs",
        icon: _ctx.link.icon,
        iconPosition: _ctx.link.iconPosition,
        variant: "tertiary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.leadTo(_ctx.link.href))),
        title: _ctx.link.href
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.link.text), 1)
        ]),
        _: 1
      }, 8, ["icon", "iconPosition", "title"]))
    : _createCommentVNode("", true)
}