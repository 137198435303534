import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snap_auth_input = _resolveComponent("snap-auth-input")!

  return (_openBlock(), _createBlock(_component_snap_auth_input, _mergeProps(_ctx.attrs, {
    value: _ctx.modelValue,
    onSnapAuthInputUpdate: _ctx.onInput
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 16, ["value", "onSnapAuthInputUpdate"]))
}